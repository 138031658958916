import './App.css';
import React, { useState, useMemo } from "react";
import { ReactTerminal, TerminalContext } from "react-terminal";
import Editor from '@monaco-editor/react';

const themes = ["light", "dark", "material-light", "material-dark", "material-ocean", "matrix", "dracula"];
const files = {
  'script.js': {
    name: 'script.js',
    language: 'javascript',
    value: `import './App.css';
    import React, { useState } from 'react';
    const name = "Andrew";`,
  },
  'style.css': {
    name: 'style.css',
    language: 'css',
    value: `html, body {
      height: 100%;
    }`,
  },
  'index.html': {
    name: 'index.html',
    language: 'html',
    value: `<html>
      <body>
        <div>Hello world!</div>
      </body>
    </html>`,
  },
};

const folders = [
  "resume",
  "source",
  "passwords"
];

function determinePath(path, input) {
  if (input === ".") return path;
  if (input === "..") return path.slice(0, path.length - 1);
  if (input.startsWith("~")) return input.split("/").slice(1);
  return [...path, ...input.split("/").filter(Boolean)];
}

function Folder({ name, index, active, onClick }) {
  const left = (index * 80) + ((index + 1) * 30);
  return <div style={{ position: "absolute", top: "10px", left: `${left}px`, filter: active ? "drop-shadow(13px 10px 23px black)" : "none" }} onClick={onClick}>
    <img src="folder.svg" width={80}></img>
    <div style={{ color: "white", textAlign: "center", fontWeight: 600, textShadow: "0 0 5px black", position: "relative", top: "-8px" }}>{name}</div>
  </div>
}

function App(props) {
  const [theme, setTheme] = useState(themes[Math.floor(Math.random() * themes.length)]);
  const [path, setPath] = useState([]);
  const [code, setCode] = useState(false);
  const [fileName, setFileName] = useState('script.js');
  const file = files[fileName];
  const [folder, setFolder] = useState("");
  const welcomeMessage = useMemo(() => <span>Random theme <strong>'{theme}'</strong> loaded<br /><br /></span>, []);
  const { setBufferedContent, setTemporaryContent } = React.useContext(TerminalContext);
  // Define commands here
  const commands = {
    whoami: "Andrew Ferko",
    skills: "Software Developer",
    cd: (input) => {
      const newPath = determinePath(path, input);
      setPath(newPath);
    },
    help: () => Object.keys(commands).join(", "),
    theme: (name) => {
      if (!themes.includes(name)) return "invalid theme";
      setTheme(name);
      return <span>Theme set to <strong>'{name}'</strong><br /></span>;
    },
    // wait: async (timeout) => {
    //   setTemporaryContent("Waiting...");
    //   await new Promise(resolve => setTimeout(() => {
    //       resolve(void 0);
    //   }, parseInt(timeout) * 1000));
    //   return 'Over!';
    // },
    // count: async (nb) => {
    //   setTemporaryContent("Counting...");
    //   nb = parseInt(nb);
    //   await Promise.all(
    //     new Array(nb).fill({}).map((value, index) => new Promise((resolve) => {
    //       const timer = setTimeout(() => {
    //         setBufferedContent((previous) => (<>
    //           {previous}
    //           <span>
    //               {index + 1}
    //           </span>
    //           {index + 1 < nb ? <br/> : ''}
    //         </>));
    //         clearTimeout(timer);
    //         resolve(void 0);
    //       }, index * 1000);
    //     }))
    //   );
    //   return <><br/>Finished</>;
    // // },
    // code: () => setCode(true)
  };
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", backgroundImage: `url('background.jpg')`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
      {!code && <>
        {folders.map((name, index) => <Folder name={name} index={index} key={name} active={folder === name} onClick={() => setFolder(folder === name ? "" : name)} />)}
        <div style={{ height: 500, width: 900, boxShadow: "0 6px 20px" }}>
        <ReactTerminal
          welcomeMessage={welcomeMessage}
          commands={commands}
          theme={theme}
          prompt={path.join("/") + ">"}
        />
      </div>
      </>}
      {code && <>
        <button disabled={fileName === 'script.js'} onClick={() => setFileName('script.js')}>
          script.js
        </button>
        <button disabled={fileName === 'style.css'} onClick={() => setFileName('style.css')}>
          style.css
        </button>
        <button disabled={fileName === 'index.html'} onClick={() => setFileName('index.html')}>
          index.html
        </button>
        <Editor height="90vh" path={file.name} defaultLanguage={file.language} defaultValue={file.value} theme="vs-dark" />
      </>}
    </div>
  );
}

export default App;
